import styled from "styled-components";
import { MOBILE_MIN_WIDTH } from "@constants";

export const TextContainer = styled.div`
    z-index: 10;
    margin-bottom: -20.625rem;
    height: 80%;
    transition: 1s;

    & p {
        transition: 0.5s;
        opacity: 0;
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        width: 90%;
        margin-bottom: -18.75rem;

        & p {
            width: 110%;
        }
    }

    @media only screen and (max-width: 445px) {
        margin-bottom: -16.875rem;
    }
`;

export const Title = styled.div`
    font-size: 2rem;
    line-height: 2.5rem;
    margin: 0;

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
`;

export const SubTitle = styled.div`
    font-weight: 200;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 1.5rem 0;
    color: #c4b180;

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        font-size: 1.25rem;
        line-height: 1.875rem;
        margin: 1rem 0;
    }
`;
