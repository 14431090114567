import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";
import { ReactSVG } from "react-svg";

export const FoundersWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    width: calc(50% - 1.25rem);
    max-width: 33.5625rem;
    height: 37.5rem;
    position: relative;
    padding: 2.5rem 2.5rem 2rem 2.5rem;
    border: 0.0625rem solid rgba(196, 177, 128, 0.3);
    overflow: hidden;
    box-sizing: border-box;
    transition: 0.5s;
    cursor: pointer;

    .founders-section__image {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        object-fit: cover;
        opacity: 1;
        transition: 0.5s;
    }

    &:first-child {
        margin-right: 2.5rem;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        &:first-child {
            margin: 0 0 1.5rem 0;
        }
    }

    &::before {
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: linear-gradient(
            180deg,
            rgba(43, 9, 52, 0) 50%,
            rgba(43, 9, 52, 0.8) 100%
        );
        z-index: 5;
    }

    &:nth-child(${props => props.activeFounder}) {
        border: 0.0625rem solid #876e62;

        .founders-section__image {
            opacity: 0;
        }

        & svg[alt="arrow icon"] {
            transform: translate(-1.8125rem, 0.125rem) rotate(135deg);
        }

        & .founder-section__text-container {
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            & p {
                opacity: 1;
            }
        }
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 36.625rem;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        width: 100%;
        max-width: 33.5rem;
        height: 32.75rem;
        padding: 1.5rem;
    }
`;

export const Corner = styled.span`
    position: absolute;
    right: -3.125rem;
    bottom: -3.125rem;
    width: 6.25rem;
    height: 6.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c4b180;
    transform: rotate(45deg);
    z-index: 15;

    & svg {
        height: 1rem;
        width: 1rem;
        transition: 0.5s;
        transform: translate(-1.8125rem, 0.125rem) rotate(-45deg);
    }
`;

export const LinkedinIcon = styled(ReactSVG)`
    margin-top: 1rem;
    border: 0.0625rem solid #ffffff4d;
    width: 2.25rem;
    height: 2.25rem;
    transition: border 0.3s, background 0.3s;

    svg {
        padding: 0.65rem;
        height: 0.9rem;
        width: 0.9rem;
    }

    &:hover {
        border: 0.0625rem solid #c4b180;
        background: #c4b1801a;
    }

    &:active {
        border: 0.0625rem solid #ffffffcc;
        background: #c4b1804d;
    }
`;
