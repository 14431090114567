import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants";

export const FoundersSectionWrapper = styled.div`
    padding: 2.5rem 0 5rem 0;

    & .page-heading {
        &__heading {
            width: calc(
                537px * 2 + 40px
            ); /* 537 is width of the founder item and there are 2 items on desktop */
            margin-bottom: 40px;
        }
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        padding: 2.5rem 0;

        & .page-heading {
            &__heading {
                width: 537px;
                margin-bottom: 24px;
            }
        }
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin-top: 38rem;

        & .page-heading__heading {
            width: 100%;
            max-width: 536px;
        }
    }
`;

export const FoundersContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        align-items: center;
        flex-direction: column;
    }
`;
