import React, { useMemo, useState } from "react";
import loadable from "@loadable/component";
import arrowIcon from "@images/about-us/ic-arrow.svg";
import linkedinIcon from "@images/ic-linkedin.svg";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { ReactSVG } from "react-svg";
import { StaticImage } from "gatsby-plugin-image";
import { Description, LayoutWrapper } from "../../../../styles/CommonStyles";
import {
    FoundersSectionWrapper,
    FoundersContainer,
} from "./styles/SectionStyles";
import { TextContainer, Title, SubTitle } from "./styles/TextStyles";
import { FoundersWrapper, Corner, LinkedinIcon } from "./styles/FoundersStyles";

const PageHeading = loadable(() => import("@common/PageHeading/PageHeading"));

const FoundersSection = () => {
    const { isMobile } = useWindowDimensions();
    const [activeFounder, setActiveFounder] = useState(null);

    const firstFounderImage = (
        <StaticImage
            src="../../../../images/about-us/firstFounder.jpg"
            alt="founder image"
            className="founders-section__image"
        />
    );

    const secondFounderImage = (
        <StaticImage
            src="../../../../images/about-us/secondFounder.jpg"
            alt="founder image"
            className="founders-section__image"
        />
    );

    const foundersInfo = useMemo(
        () => [
            {
                imageMarkup: firstFounderImage,
                founderName: "Roman Zomko",
                jobPosition: "CEO and Co-Founder of Impressit",
                firstParagraph:
                    "An expert in customer experience and team management." +
                    " Over the last 7 years helping digital startups and SMEs to fill the gap in their technical capabilities.",
                secondParagraph:
                    "Commitment to excellence in client experience, ethical culture, genuine desire to help grow ideas into " +
                    "operating businesses, and a focus on getting the job done are the things you can expect from Roman " +
                    "during the whole cooperation process.",
                linkedinUrl: "https://www.linkedin.com/in/romanzomko/",
                ariaLabel: "Roman Zomko linkedin"
            },
            {
                imageMarkup: secondFounderImage,
                founderName: "Andrew Lekh",
                jobPosition: "CTO and Co-Founder of Impressit",
                firstParagraph:
                    "A passionate technology enthusiast with 11 years" +
                    " of experience in software architecture and design. This includes " +
                    "the development of complex e-commerce platforms, SaaS solutions, ERPs, online marketplaces, and games.",
                secondParagraph:
                    "Responsible for every tech aspect at Impressit and control" +
                    " the entire course of every project delivery.",
                linkedinUrl: "https://www.linkedin.com/in/andrew-lekh/",
                ariaLabel: "Andrew Lekh linkedin"
            },
        ],
        [isMobile]
    );

    return (
        <LayoutWrapper>
            <FoundersSectionWrapper>
                <PageHeading heading="Founders" />
                <FoundersContainer>
                    {foundersInfo.map((founderInfo, index) => {
                        const {
                            founderName,
                            jobPosition,
                            firstParagraph,
                            secondParagraph,
                            imageMarkup,
                            linkedinUrl,
                            ariaLabel
                        } = founderInfo;
                        return (
                            <FoundersWrapper
                                key={index}
                                activeFounder={activeFounder}
                                onMouseEnter={() => setActiveFounder(index + 1)}
                                onMouseLeave={() => setActiveFounder(null)}
                                onTouchEnd={() =>
                                    setActiveFounder(prevState =>
                                        prevState ? null : index + 1
                                    )
                                }
                            >
                                {imageMarkup}
                                <TextContainer className="founder-section__text-container">
                                    <Title>{founderName}</Title>
                                    <SubTitle>{jobPosition}</SubTitle>
                                    <Description>{firstParagraph}</Description>
                                    <Description>{secondParagraph}</Description>
                                    <a href={linkedinUrl} aria-label={ariaLabel} target="_blank">
                                        <LinkedinIcon
                                            src={linkedinIcon}
                                            alt="linkedin icon"
                                        />
                                    </a>
                                </TextContainer>
                                <Corner>
                                    <ReactSVG
                                        src={arrowIcon}
                                        alt="arrow icon"
                                    />
                                </Corner>
                            </FoundersWrapper>
                        );
                    })}
                </FoundersContainer>
            </FoundersSectionWrapper>
        </LayoutWrapper>
    );
};

export default FoundersSection;
